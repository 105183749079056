@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

@font-face {
  font-family: "Modeseven";
  src: local("Modeseven"), url("fonts/Modeseven.ttf") format("truetype");
}
@font-face {
  font-family: "get_schwifty";
  src: local("get_schwifty"), url("fonts/get_schwifty.ttf") format("truetype");
}

/* scrollbar design */
::-webkit-scrollbar {
  width: 5px;
  background-color: #000000;
  border: 0;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #363636c4;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
}
