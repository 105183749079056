.buyButton {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  color: #000000;
  background-color: #ffdd00;
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 0.5rem;
  font-size: 1rem;
  letter-spacing: 0.6px;
  box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5);
  font-family: cursive;
  margin-top: 2rem;
}
.buyButton:hover,
.buyButton:active,
.buyButton:focus {
  box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5);
  opacity: 0.85;
}

.coffeeImage {
  height: 2vh;
  box-shadow: none;
  border: none;
  vertical-align: middle;
}

.coffeeButtonText {
  margin-left: 15px;
  font-size: 0.8rem;
  vertical-align: middle;
}
