#owee {
  position: absolute;
  -webkit-animation: linear infinite;
  -webkit-animation-name: run;
  -webkit-animation-duration: 10s;
}
@keyframes run {
  0% {
    top: -20%;
    left: -30%;
    scale: 1;
  }
  50% {
    top: 50%;
    left: 50%;
    scale: 0.5;
  }
  100% {
    top: 0%;
    left: 100%;
    scale: 0;
  }
}
