.typing-demo {
  padding-right: 2px;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  border-color: transparent;
  line-height: 1em;
  /* font-family: monospace; */
  /* font-size: 0.8em; */
  /* animation-delay: 2s !important; */
  /* opacity: 1; */
}

@keyframes typing {
  from {
    width: 0;
    /* opacity: 0 */
  }
}

@keyframes txt {
  to {
    color: #fff;
    /* opacity: 0 */
  }
}

@keyframes blink {
  50% {
    border-color: #fff;
  }
}

.line-fade-out {
  opacity: 0 !important;
  transition: opacity 1s ease-out;
}
