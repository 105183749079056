.logout_wrapper:hover .logout_label {
  opacity: 1;
}

.logout_wrapper {
  position: relative;
}

.logout_label {
  opacity: 0;
  position: absolute;
  left: -60px;
}

.iconOverlay {
  display: none;
}

.iconOverlay_w:hover .iconOverlay {
  display: block;
}
