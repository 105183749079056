.coinRotate {
  transition: all 1s ease-in-out;
  animation: coinRotate 6s infinite;
}

@keyframes coinRotate {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
